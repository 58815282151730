<template>
  <div id="dashboard-content">
    <h4><b>Setor Tugas</b></h4>
    <div class="mt-4 card border-0 shadow-sm p-4">
      <form
        @submit.prevent="portfolioSubmit"
        enctype="multipart/form-data"
        accept-charset="utf-8"
      >
        <div class="row">
          <div class="col-md-12">
            <div class="form-group">
              <label>Judul <span class="text-danger">*</span></label>
              <input
                v-model="portfolio.title"
                type="text"
                class="form-control"
                placeholder="Judul"
                required="required"
              />
              <div class="help-block with-errors"></div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label>Tipe</label>
              <select v-model="portfolio.type" class="form-control">
                <option value="Tugas">Tugas Workshop</option>
              </select>
            </div>
          </div>
          <div class="col-md-6">
            <input
              v-model="portfolio.year"
              name="year"
              type="hidden"
              class="form-control"
              placeholder="Tahun"
              data-error="Valid email is required."
              required="required"
              value=""
            />
          </div>
          <div class="col-md-12">
            <div class="form-group">
              <label>Deskripsi <span class="text-danger">*</span></label>
              <textarea
                v-model="portfolio.description"
                class="form-control"
                placeholder="Misalnya jenis naskah, nama mentor, dan lainnya selengkap-lengkapnya"
                required="required"
              ></textarea>
            </div>
          </div>
          <div class="col-md-12 mb-4">
            <div class="row d-flex align-items-center">
              <div class="col-md-12">
                <div class="form-group">
                  <label>Lampiran (Doc/Docx/PDF/Zip) Maks 10MB</label>
                  <FileUploader
                    @select-file="setFile"
                    :uploaded="portfolio.attachment"
                    id="lampiran"
                  />
                </div>
                <button
                  v-if="!$store.state.loadUploadFile"
                  @click="uploadingFile()"
                  type="button"
                  class="btn btn-primary btn-block"
                >
                  Upload
                </button>
                <button
                  type="button"
                  v-if="$store.state.loadUploadFile"
                  class="btn btn-primary btn-block"
                >
                  <span class="spinner-border spinner-border-sm"></span>
                  Sedang diproses
                </button>
              </div>
            </div>
          </div>
          <div class="col-md-12">
            <div class="form-group">
              <input
                v-model="portfolio.link"
                type="hidden"
                class="form-control"
                placeholder="Tautan portfolio"
              />
            </div>
          </div>
        </div>
        <div class="text-right">
          <button v-if="$store.state.loadPage" class="btn btn-primary">
            <span class="spinner-border spinner-border-sm"></span>
            Sedang diproses..
          </button>
          <button
            v-else
            type="submit"
            class="btn btn-primary rounded-pill px-3"
          >
            Simpan
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import FileUploader from "@/components/global/dashboard/FileUploader.vue";
import Swal from "sweetalert2";
export default {
  components: { FileUploader },
  name: "PortfolioAddPage",
  data() {
    return {
      id: this.$route.params.id,
      portfolio: {
        title: "",
        type: "Tugas",
        attachment: "",
        description: "",
        year: "",
        link: "",
      },
      file: null,
    };
  },
  methods: {
    ...mapActions([
      "addNewPortfolio",
      "fetchDetailPortfolio",
      "updateDetailPortfolio",
      "uploadFile",
    ]),
    setFile(file) {
      this.file = file;
    },
    setValue(portfolio) {
      portfolio = portfolio || this.$store.state.portfolio;
      this.portfolio.id = portfolio.id;
      this.portfolio.title = portfolio.title;
      this.portfolio.type = portfolio.type;
      this.portfolio.description = portfolio.description;
      this.portfolio.attachment = portfolio.attachment;
      this.portfolio.year = portfolio.year;
      this.portfolio.link = portfolio.link;
    },
    uploadingFile() {
      if (this.file === null) {
        Swal.fire({
          icon: "warning",
          title: "Berkas portfolio harus diisi",
        });
      } else {
        if (this.file.size >= 10000000) {
          Swal.fire({
            icon: "warning",
            title: "Ukuran berkas terlalu besar, maksimal 10 mb",
          });
        } else {
          this.uploadFile(this.file)
            .then((res) => {
              if (res.status === "success") {
                this.portfolio.attachment = res.file;
                Swal.fire({
                  icon: "success",
                  title: "Berhasil",
                  text: "Berhasil diunggah, selanjutnya silahkan klik tombol simpan"
                });
              } else {
                Swal.fire({
                  icon: "error",
                  title: res.message,
                });
              }
            })
            .catch((error) => {
              Swal.fire({
                icon: "error",
                title: error.message,
              });
            });
        }
      }
    },
    portfolioSubmit() {
      if (!this.portfolio.attachment && !this.portfolio.link) {
        Swal.fire({
          icon: "warning",
          title: "Silahkan isi tautan atau berkas portfolio terlebih dahulu",
        });
      } else {
        let submit = this.id
          ? this.updateDetailPortfolio
          : this.addNewPortfolio;

        submit(this.portfolio).then((response) => {
          if (response.data.status == "failed") {
            Swal.fire({
              icon: "error",
              title: "Gagal disimpan",
            });
          } else {
            Swal.fire({
              imageUrl:
                "https://ik.imagekit.io/8jggdaymrs/app.buku.kemdikbud.go.id/Done.png",
              imageAlt: "Done",
              title: "<h4>Berhasil</h4>",
              text: this.id
                ? "Perubahan data tugas berhasil tersimpan."
                : "Tugas baru berhasil tersimpan ",
            }).then((status) => {
              if (status.isConfirmed || status.isDismissed) {
                this.$router.push("/user/tugas");
              }
            });
          }
        });
      }
    },
  },
  beforeCreate() {
    const logged_in = localStorage.getItem("user");
    if (!logged_in) {
      this.$router.push("/login");
    }
  },
  created() {
    if (this.id) this.fetchDetailPortfolio(this.id).then(this.setValue);
  },
};
</script>

<style></style>
